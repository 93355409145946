const OurTopPartners = () => {
  return (
    <section className="grid place-items-center mx-auto ">
      <h1 className="text-20pxff text-white font-semibold my-10">🟢OUR TOP PARTNERS🟢</h1>

      <div className="">
        <div className="partner-wrap grid place-items-center">
          <ul className="flex max-md:block items-center place-items-center mx-auto px-20 rounded-xl max-md:px-5">
            <li>
              <a href="/">
                <img decoding="async" src="https://waxchain.io/wp-content/uploads/2024/03/Group-17.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://bscscan.com/token/0xb9162e3113f02b881F55E1d74e2EdEFAc92b0314">
                <img decoding="async" src="https://waxchain.io/wp-content/uploads/2024/03/bnb-scan.png" alt="" />
              </a>
            </li>
            <li>
              <a href="/">
                <img decoding="async" src="https://waxchain.io/wp-content/uploads/2024/03/Group-16.png" alt="" />
              </a>
            </li>
            <li>
              <a href="/">
                <img decoding="async" src="https://waxchain.io/wp-content/uploads/2024/03/cmcx.png" alt="" />
              </a>
            </li>
            <li>
              <a href="/">
                <img decoding="async" src="https://waxchain.io/wp-content/uploads/2024/03/mm.png" alt="" />
              </a>
            </li>
          </ul>
          <ul className="flex max-md:block items-center place-items-center mx-auto px-20 rounded-xl max-md:px-5 patnerimagersize">
            <li>
              <a href="/">
                <img decoding="async" src="https://waxchain.io/wp-content/uploads/2024/04/Untitled1-1.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://app.solidproof.io/projects/waxchain">
                <img decoding="async" src="/images/derdd-removebg-preview.png" alt="" />
              </a>
            </li>
            <li style={{ marginLeft: '18px' }}>
              <a href="https://www.dexview.com/bsc/0xb9162e3113f02b881F55E1d74e2EdEFAc92b0314">
                <img decoding="async" src="/images/dexview-300x70-removebg-preview.png" alt="" />
              </a>
            </li>
            <li>
              <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x0f10c1e8aa6bdbc31d856103f47ce436444fd555?t=1713373528211">
                <img
                  decoding="async"
                  src="https://waxchain.io/wp-content/uploads/2024/04/721676109811-300x157.png"
                  alt=""
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default OurTopPartners
